*,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
div,
button,
a,
li,

label {
    font-family: 'Aeonik';
    font-weight: 500;
}

h1 {
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    line-height: 130%;
}

h2 {
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    line-height: 130%;
}

h3 {
    font-size: medium;
    font-weight: 500;
}


h4 {
    font-size: small;
    font-weight: 500;
}

h5 {
    font-size: smaller;
    font-weight: 500;
}


h6 {
    font-size: x-small;
    font-weight: 300;
}

label {
    font-size: smaller !important;
    font-weight: 500 !important;
    color: $gray-color !important;
}



html,
body,
#root {
    height: 100%;
    width: 100%;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f4f4f4;
    overflow: hidden;
}

.text-dark {
    color: $dark-color;
}

.text-gray {
    color: $gray-color;
}

.text-primary {
    color: $primary-color;
}

.text-600 {
    font-weight: 600;

}

.bold {
    font-weight: bold;
}

.space {
    margin: 0 4px;
}

.crossed {
    text-decoration: line-through;
}



.bg-error {
    background-color: $error-color;
}

.bg-success {
    background-color: $success-color;
}

.bg-warning {
    background-color: $warning-color;
}

.bg-accent-error {
    background-color: $bg-error-color;
}

.bg-accent-success {
    background-color: $bg-success-color;
}

.bg-accent-warning {
    background-color: $bg-gray-color;
}


.bg-accent-gray {
    background-color: $btn-bg-gray-color;
}


.bg-accent-primary {
    background-color: $bg-primary-color;
}


.loader-container {
    height: 400px;
    @include flex-column-center
}


.layout {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.flex-start {
    @include flex-start;
}

.flex-v-end {
    @include flex-v-end;
}


.breadcrumb {
    @include flex-start;
    margin: 10px 0;

    .breadcrumb_item {
        @include flex-between;

        svg {
            height: 16px;
            width: 16px;
            margin: 0 4px;
        }
    }
}

span.ant-select-arrow span.ant-select-suffix {
    color: $primary-color;
}

button {
    width: 100%;
}

button.primary {
    background-color: $primary-color;
    color: $bg-white-color;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-divider label {
    font-size: 12px;
}

.mr-5 {
    margin-right: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mb-50 {
    margin-bottom: 50px;
}

.ml-5 {
    margin-left: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-25 {
    margin-top: 25px;
}

.p-4 {
    padding: 4px
}

.qrcode_container {
    @include flex-center;

    .qrcode {
        border: none;
    }

}

.uppercase {
    text-transform: uppercase;
}

.w-full {
    width: 100%
}

.crypto_address {
    @include flex-between;

    button {
        border-radius: 50%;
        background-color: $bg-blue;
        font-weight: 600;
        padding: 8px 4px;
        border: none;
        font-size: 12px;
        width: 70px;
    }
}

.modal {
    display: flex;
    width: 375px;
    padding: 28px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 36px;

    .ant-modal-title {
        text-align: center;
    }

    .ant-modal-close-x {
        background-color: $bg-blue;
        color: $primary-color;
        border-radius: 50%;
        padding: 2px;
    }

    .ant-modal-close:hover {
        border-radius: 50%;

    }
}

.status_container {
    @include flex-column-center;

    .status_icon_container {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        @include flex-center;

        .status_icon {
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }
    }
}

.receip-btn {
    padding: 22px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    border: none;
    @include flex-center;
}

.receipt-icon {
    width: 20px;
    height: 20px;
}

.text-center {
    text-align: center;
}

.product-image {
    object-fit: contain;
}

.centered {
    @include flex-center;
}
.lang-btn{
    width: 50px;
    height: 50px;
    img{
        width: 30px;
        height: 30px;
    }
}

.ant-float-btn-content, .ant-float-btn-icon{
    width: 100%;
    height: 100%;
    @include flex-center;
}
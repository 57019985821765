@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-Air.otf) format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-AirItalic.otf) format('opentype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-Thin.otf) format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-ThinItalic.otf) format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-Thin.otf) format('opentype');
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-ThinItalic.otf) format('opentype');
    font-weight: lighter;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-Light.otf) format('otf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-LightItalic.otf) format('opentype');
    font-weight: 400;
    font-style: italic;
}


@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-Regular.otf) format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-RegularItalic.otf) format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-RegularItalic.otf) format('opentype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-Medium.otf) format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-MediumItalic.otf) format('opentype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-Bold.otf) format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-BoldItalic.otf) format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-Bold.otf) format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-BoldItalic.otf) format('opentype');
    font-weight: bold;
    font-style: italic;
}


@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-Black.otf) format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-BlackItalic.otf) format('opentype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-Black.otf) format('opentype');
    font-weight: bolder;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url(../../../public/assets/fonts/Aeonik-BlackItalic.otf) format('opentype');
    font-weight: bolder;
    font-style: italic;
}
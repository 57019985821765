$primary-color: #505AE9;
$gray-color: #8789AF;
$dark-color: #101561;
$success-color: #0CCA4A;
$bg-success-color: #E7FAED;
$error-color: #FF1D15;
$bg-error-color: #FFF4F3;
$bg-primary-color: #F6F6FE;
$warning-color: #FFB400;
$btn-bg-gray-color: #F9FAFB;
$bg-gray-color: #F3F3F7;
$bg-white-color: #FFF;
$bg-blue: #EEEFFD;


$box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.10);



$btn-radius: 8px;
$card-radius: 12px;


@mixin flex-v-center {
    display: flex;
    align-items: center;
}

@mixin flex-baseline {
    display: flex;
    align-items: baseline;
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-h-center {
    display: flex;
    justify-content: center;
}

@mixin flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


@mixin flex-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@mixin flex-v-end {
    display: flex;
    align-items: flex-end;
}

@mixin flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
@mixin sm {
  @media (min-width: 320px) and (max-width: 428px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 429px) and (max-width: 1024px) {
    @content;
  }
}

.payment_container {
  display: flex;
  justify-content: center;
  align-items: center;

  @include sm() {
    width: 100%;
    padding: 20px;
  }

  .payment_container_card {
    width: 400px;

    @include sm() {
      width: 100%;
    }


    .form-group {
      margin-bottom: 20px;

      .ant-input,
      .ant-select-selector {
        height: 40px;
      }
    }

    .userpaylink-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .qty {
        width: 161px;
        padding: 0.3rem;
        background-color: #000;
        color: #fff;
        border-radius: 0.3rem;
        text-align: center;
        margin: 1.7rem 0;
      }

      p {
        text-align: center;
      }

      img {
        width: 100%;
        border-radius: 0.5rem;
      }

      .content-form {
        width: 25rem;
      }
    }
  }

  .amount_container {
    @include flex-column;
    background-color: $btn-bg-gray-color;
    border-radius: 8px;
    padding: 11px 0 11px 24px;
    margin: 25px 0;

    .amount_value {
      @include flex-v-end
    }
  }

  .crypto_container {
    @include flex-column;
    border: solid 2px $btn-bg-gray-color;
    border-radius: 8px;
    padding: 11px 24px 11px 24px;
    margin: 25px 0;

  }

  .payment-btn {
    @include flex-between;
    button {
        @include flex-center;
        height: 40px;
        padding: 14px 17px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1px solid $bg-gray-color;
        background: #FFF;
    }
}
}

.respContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @include sm() {
    padding: 20px;
  }

  .responseMsg {
    width: 700px;
    min-height: 300px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 18px;

    @include sm() {
      width: 100%;
    }

    .respIcon {
      margin-bottom: 50px;
      font-size: 80px;
    }
  }

  .error {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.error-content {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    text-align: center;
    margin-top: 1rem;
  }

  p {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.btns {
  width: 230px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-top: 1rem;

  .btn {
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    border: 1px solid black;
  }
}